import { render, staticRenderFns } from "./circular-comp.vue?vue&type=template&id=61bb3153&scoped=true&"
import script from "./circular-comp.vue?vue&type=script&lang=js&"
export * from "./circular-comp.vue?vue&type=script&lang=js&"
import style0 from "./circular-comp.vue?vue&type=style&index=0&id=61bb3153&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61bb3153",
  null
  
)

export default component.exports